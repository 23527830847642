body {
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  cursor: url(../stuff/13/cursor.png) 13 13, auto;

  &.thunderstorm {
    cursor: url(../stuff/13/cursor-blitz.png) 10 9, auto;
  }
  &.fog {
    cursor: url(../stuff/13/cursor-fog.png) 14 14, auto;
  }
  &.rain {
    cursor: url(../stuff/13/cursor-rain.png) 15 14, auto;
  }
  &.snow {
    cursor: url(../stuff/13/cursor-snow.png) 14 14, auto;
  }
  &.clouds {
    cursor: url(../stuff/13/cursor-clooud.png) 10 9, auto;
  }
  &.default {
    cursor: url(../stuff/13/cursor.png) 15 15, auto;
  }

  button {
    &:focus {
      outline: 0;
    }
  }
}

.wrapper {
  z-index: 1;
  max-width: 650px;
  width: 90vw;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100vw;
  }

  @media screen and (max-width: 500px) {
    height: 90vh;
  }
}

.wrapper-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.initials {
  position: absolute;
  font-weight: 800;
  font-size: 540px;
  padding-left: 10px;
  line-height: 0.8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  letter-spacing: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001bc4;

  @media screen and (max-width: 750px) {
    padding-left: 75px;
    letter-spacing: 8rem;
    font-size: 400px;
    align-items: flex-start;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0px;
    letter-spacing: 2rem;
    margin-left: 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 300px;
    height: auto;
    letter-spacing: 2rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 250px;
    height: auto;
  }

  @media screen and (max-width: 350px) {
    letter-spacing: 1rem;
  }
}

.text {
  position: relative;
  height: 100%;
  width: 30%;
  padding: 20px;
  line-height: 2;
  margin-right: 20%;
  margin-top: -5px;
  text-align: right;

  @media screen and (max-width: 750px) {
    line-height: 2;
    padding: 5px 20px;
    margin-top: 0;
  }

  @media screen and (max-width: 500px) {
    width: 50%;
    margin-right: 0;
    text-align: left;
    height: 40%;
    margin-top: 50%;
  }
}

.text__text {
  font-size: 16px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.3s ease;

  &--hidden {
    display: none;
  }

  @media screen and (max-width: 750px) {
    font-size: 14px;
  }
}

.active {
  .text__text {
    display: none;
  }

  .text__text--hidden {
    display: block;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.3s ease;
  }
}

.active-animated {
  .text__text {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.3s ease;
  }

  .text__text--hidden {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.3s ease;
  }
}

.anchor {
  color: #ffffff;
  text-decoration: underline;
  background-color: transparent;
  box-shadow: transparent;
  text-shadow: none;
  border: none;
  cursor: pointer;
}

.info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  font-size: 10px;
  color: #ffffff;
  padding: 12px;
  text-align: center;

  @media screen and (max-width: 500px) {
    text-align: left;
  }
}

.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  opacity: 1;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
  transition: all 0.2s ease;
  transform: translateX(0px) translateY(-2px);

  @media screen and (max-width: 500px) {
    background-color: transparent !important;
  }

  .thunderstorm & {
    transform: translateX(2px) translateY(2px);
  }
  .fog &,
  .rain &,
  .snow &,
  .default & {
    transform: translateX(5px) translateY(3px);
  }

  &.over {
    background-color: white !important;
    width: 50px;
    height: 50px;
    transform: translateX(-15px) translateY(-15px);
    transition: all 0.2s ease;
    opacity: 0.8;
  }
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
